export const useDataUtils = () => {
    const speciesColors = [
      '#3b82f6', '#10b981', '#f59e0b', '#8b5cf6', '#ec4899',
      '#14b8a6', '#f97316', '#6366f1', '#84cc16', '#06b6d4',
      '#a855f7', '#ef4444', '#22c55e', '#eab308', '#64748b',
      '#0ea5e9', '#d946ef', '#14b8a6', '#f43f5e', '#8b5cf6',
      '#1e40af', '#065f46', '#92400e', '#5b21b6', '#9d174d',
      '#115e59', '#93c5fd', '#6ee7b7', '#fcd34d', '#c4b5fd',
      '#f9a8d4', '#5eead4',
    ];
  
    const mapSpeciesToColors = (species: string[]): { [key: string]: string } => {
      return species.reduce((acc, speciesName, index) => {
        acc[speciesName] = speciesColors[index % speciesColors.length];
        return acc;
      }, {});
    };
  
    return {
      mapSpeciesToColors,
    };
  };